// see src/stylesheets/sites/_common.scss
$product-category-header-bg: inherit; // product category header bg color
$product-category-active-filters-bg: inherit; // product category active filters bg color
$product-category-groups-filters-bg: inherit; // product category filter groups bg color
$product-category-rounded: false; // apply a rounded border to product category sections

// override default colors
$dark: #2b2b2b;
$warning: #d4c251;
$success: #477d60;
$danger: #687970;

$footer: #343434 !important;
$footer-brand: #1b1a1a !important;

$menu-sm-bg: #343434;

// override main menu colors
$menu-bg: #343434;
$menu-dropdown-bg: #343434;

// override Campaign outlet button color
$outlet-btn-color: #b5a192;

// override breadcrumbs colors (fg|display-home MUST be present)
$breadcrumb-fg: inherit; // use `inherit` for default color
$breadcrumb-display-home: inherit; // use `none` to hide home icon, default to `inherit`
// $breadcrumb-bg: inherit; // breadcrumb bg color
// $breadcrumb-divider-color: inherit; // breadcrumb divider item color
// $breadcrumb-active-color: inherit; // breadcrumb active item fg color

// override Campaign buygift button color
// $buygift-btn-color: $dark;

// override route links color
$link-color: #008060;

// override product subcategory color
$product-subcategory-bg: $secondary;
$product-subcategory-fg: $white;

// override product discount ribbon color
$discount-ribbon-bg: $danger;
$discount-ribbon-fg: $white;

// override product-serie-list-item color
$product-series-summary-bg: #000000;
$product-series-summary-fg: #ffffff;
$product-series-button-bg: #6c757d;

@media (min-width: 768px) {
  .page-header {
    box-shadow: none !important;
    //margin-top: 1rem;
  }
  .menu-bar-item-dropdown .dropdown-menu {
    box-shadow: none !important;
  }
}

.navbar-brand {
  padding: 0.8rem;
}

.footer-links-column {
  border-right: 1px solid #f8f9fa36 !important;
}

.campaign-buttons {
  display: none;
}

.valentines {
  background-color: #c175ff !important;
  font-weight: bold;
}

.cybermonday {
background-color: #ff0000 !important;
color: #ff01ba !important;
font-weight: bold;
}

.blackmonth {
  background-color: #000000 !important;
}

.cyber {
  background-color: #007ea6 !important;
}